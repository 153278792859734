<template>
  <div>
    <CRow>
      <CCol md="8">
        <template lang="html">
          <div><CDropdownItem @click="action">merge</CDropdownItem></div>
          <div>
            <!-- <vs-button @click="popupActive = true" color="primary" type="border"
              >Open Default popup</vs-button
            > -->

            <div class="center">
              <vs-popup
                :active.sync="popupActive"
                title="Merge Users"
                style="none"
                icon="none"
              >
                <CForm>
                  <p style="font-size: 18px">
                    Are you sure you want to {{ subgroup }} merge?
                  </p>
                  <span class="col-md-2">
                    <input type="checkbox" class="mr-2" /> Yes
                  </span>
                  <span class="col-md-2">
                    <input type="checkbox" class="mr-2" /> No
                  </span>
                  <div align="right">
                    <CButton
                      class="mr-2"
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="mergeDepartment(item.subGroupId)"
                      >Save</CButton
                    >
                    <CButton
                      class="mr-2"
                      type="Cancel"
                      size="sm"
                      color="danger"
                      @click="cancel()"
                      >Cancel</CButton
                    >
                  </div>
                </CForm>
                <!-- </CCardBody>
                </CCard> -->
              </vs-popup>
            </div>
          </div>
        </template></CCol
      ></CRow
    >
  </div>
</template>

<script>
import Vue from "vue";
import { vsPopup } from "vuesax";
import "vuesax/dist/vuesax.css";

Vue.use(vsPopup);

export default {
  name: "mergeQC",
  data() {
    return {
      subgroup: "QC",
      subgroup2: "YC",
      popupActive: true,

      items: [],
    };
  },
  methods: {
    mergeDepartment(subGroupId) {
      console.log("merge called with", this.subGroupId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/merge/subgroup/" +
          subGroupId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },
    cancel() {
      window.history.back();
    },
  },
};
// documnet.getElementByClassName("title").title = "hidden";
</script>

<style >
@media print {
  .vs-popup-title {
    display: none;
  }
}
vs-popup--header {
  display: none;
}

input {
  font-size: 19px;
}
/* .title {
  display: hidden;
} */
</style>